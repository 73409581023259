.page-title {
  padding-top: 115px;
  text-align: center;
  background-color: #f4f4f4;
}

.page-title h2 {
  color: #333;
  font-size: 32px;
  margin-bottom: 10px;
}

.page-title .subtitle {
  font-size: 18px;
  color: #777;
}

.form-container {
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.selected-option {
  display: flex;
  align-items: center;
  gap: 10px; /* Added space between flag and name after selection */
}

.country-flag-selected {
  width: 35px;
  height: 25px;
  border-radius: 4px;
}

.country-name-selected {
  font-size: 16px; /* Slightly larger font size for the selected country */
  color: #333;
}

.custom-dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
}

.selected-country {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.options-list {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
  display: none;
}

.custom-dropdown.open .options-list {
  display: block;
}

.custom-option {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 6px; /* Reduced gap between flag and country name */
  transition: background-color 0.3s ease;
  font-size: 14px; /* Slightly smaller font size for better balance */
}

.custom-option:hover {
  background-color: #f4f4f4;
}

.country-flag {
  width: 35px; /* Reduced width for a compact look */
  height: 25px; /* Adjusted height for better flag proportions */
  border-radius: 4px;
}

.google-form-container {
  width: 100%;
}

.loading-spinner {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  color: #0062cc;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .form-container {
    padding: 20px;
  }

  .country-flag {
    width: 30px;
    height: 25px;
  }
}
