.offer-card {
    display: block;
    border-radius: 15px;  /* Curved borders */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Light shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;  /* Ensures text inherits color and removes default link styles */
    overflow: hidden;
  }
  
  .offer-card:hover {
    transform: translateY(-5px);  /* Hover effect: Slight lift */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);  /* Shadow intensifies on hover */
  }
  
  .offer-card img {
    width: 100%;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .offer-card .card-body {
    padding: 15px;
  }
  
  .offer-card .card-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .offer-card .card-text {
    font-size: 1rem;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .offer-card {
      margin-bottom: 20px;
    }
  }
  