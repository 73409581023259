/* SitemapPage.css */

.sitemap-container {
    max-width: 800px; /* Set a max width */
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add padding */
    
    color: #333; /* Text color */
  }
  
  .sitemap-list {
    list-style-type: none; /* Remove default list style */
    padding: 0; /* Remove padding */
  }
  
  .sitemap-item {
    margin: 10px 0; /* Add some space between items */
  }
  
  .sitemap-item a {
    text-decoration: none; /* Remove underline from links */
    color: #3c3d3d; /* Link color */
    transition: color 0.3s; /* Smooth transition for hover */
  }
  
  .sitemap-item a:hover {
    color: #000000; /* Darker color on hover */
  }
  