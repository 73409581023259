/* Contact.css */

.page-title {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
}

.page-title h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.contact-info {
    padding: 50px 0;
}

.office-info {
    margin-bottom: 30px;
}

.office-info h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.office-info p {
    line-height: 1.8;
}

.country-selector {
    padding: 50px 0;
    text-align: center;
}

.country-selector .dropdown-toggle {
    background-color: #000;
    color: #fff;
    border: none;
}

.country-selector .dropdown-toggle:hover {
    background-color: #333;
}

.social-media {
    padding: 50px 0;
    text-align: center;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.social-icon {
    font-size: 24px;
    color: #000;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #007bff;
}

.social-icon i {
    vertical-align: middle;
}

.social-icon.facebook { color: #3b5998; }
.social-icon.twitter { color: #1da1f2; }
.social-icon.instagram { color: #e1306c; }
.social-icon.linkedin { color: #0077b5; }
.social-icon.telegram { color: #0088cc; }
