

/* Offer Details Section */
.offer-details {
  background-color: #ffffff;
  padding: 50px 0;
  margin-top: 30px;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.offer-details img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
}

.offer-details h3 {
  font-size: 1.8rem;
  margin-top: 30px;
  color: #444;
}

.offer-details p, .offer-details ul {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

.terms-list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.terms-list li {
  margin-bottom: 10px;
}

.table {
  margin-top: 30px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
}

.table th, .table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-align: left;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Latest Campaigns Section */
.latest-campaigns {
  background-color: #f8f9fc;
  padding: 80px 0;
}

.latest-campaigns h3 {
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

.card {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-img-top:hover {
  transform: scale(1.05);
}

.card-body {
  padding: 20px;
  background-color: #fff;
}

.card-title {
  font-size: 1.3rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.text-muted {
  font-size: 0.9rem;
  color: #888;
}

/* Media Queries */
@media (max-width: 768px) {
  .section-title h2 {
    font-size: 2.2rem;
  }

  .latest-campaigns {
    padding: 60px 0;
  }

  .card-img-top {
    height: auto;
  }
}
